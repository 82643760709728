<template>
    <component-container
        :card-title="$t('component_exportSetting_title')"
        :card-subtitle="$t('component_exportSetting_subtitle')"
        :height="(!dataSources || !dataSources.data || dataSources.data.length ===0) ? 510 : 700"


        :component-data="{
            response: { isLoaded:true, error:false,  data: true },
            hasData: true
        }"
    >

        <template v-slot:content>

            <div v-if="!dataSources || !dataSources.data || dataSources.data.length ===0" class="pb-10">
                <data-source-configuration  data-source-type="google_sheet" class="mt-8" @addExternalDataSource="initComponentData"/>
            </div>

            <v-row class="v-application" v-else>
                <v-col :cols="12">
                    <img src="@/assets/images/google_sheet_logo_mini.png" class="inline-block mr-5" style="vertical-align:middle;width: 30px" alt="Google Sheet Logo">
                    <v-switch
                        v-model="useExport"
                        class="inline-block"
                        inset
                        style="vertical-align: middle"
                        :label="$t('component_exportSetting_switchUseExportLabel')"
                        @change="saveConfig"
                    ></v-switch>
                </v-col>

                <v-col :cols="12">
                    <v-select
                        v-model="selectedDataSource"
                        :items="dataSources.data"
                        item-text="identifier"
                        item-value="id"
                        hide-details
                        return-object
                        :label="$t('component_exportSetting_selectAccountLabel')"
                        outlined
                        :disabled="!useExport"
                        @change="saveConfig('account')"
                    />

                    <div style="height: 18px">
                        <v-fade-transition>
                        <span
                            class="green--text text--darken-1"
                            v-show="displayConfirmSaveAccount"
                        >
                            {{ $t('common_changeSaved') }}
                        </span>
                        </v-fade-transition>
                    </div>

                </v-col>

                <v-col cols="12" class="text-center">
                    <v-icon x-large color="grey lighten-1" style="margin-top: -20px">mdi-arrow-down-thin</v-icon>
                </v-col>

                <v-col :cols="12">
                    <v-select
                        v-model="selectedFrequency"
                        :items="frequencies"
                        item-text="name"
                        item-value="value"
                        hide-details
                        :label="$t('component_exportSetting_selectFrequencyLabel')"
                        outlined
                        :disabled="!useExport"
                        @change="saveConfig('frequency')"
                    />
                    <div style="height: 18px">
                        <v-fade-transition>
                        <span
                            class="green--text text--darken-1"
                            v-show="displayConfirmSaveFrequency"
                        >
                            {{ $t('common_changeSaved') }}
                        </span>
                        </v-fade-transition>
                    </div>

                </v-col>


                <v-col :cols="12"  style="min-height: 150px">
                    <p v-html="displayScheduledSummary" v-if="useExport"/>
                </v-col>

            </v-row>


            <div class="v-application">
                <v-alert
                    text
                    dense
                    outlined
                    color="orange"
                    icon="mdi-information-outline"
                    border="left"
                    class="bordered-alert mt-2"
                >
                    <div v-html="$t(`component_exportSetting_betaText`)"></div>
                </v-alert>
            </div>



        </template>

    </component-container>


</template>


<script>

import dataSourceApiCalls from "@/api/axios/configuration/analytics";
import projectApiCalls from "@/api/axios/configuration/project";
import exportApiCalls from "@/api/axios/configuration/export";
import DataSourceConfiguration from "@/components/yooda-components/DataSourceConfiguration.vue";


export default {
    data(){
        return{
            projectInfo:null,
            currentExport:null,
            useExport:false,
            selectedDataSource:null,
            dataSources:null,
            displayConfirmSaveAccount:false,
            displayConfirmSaveFrequency:false,
            frequencies:[
                {name:this.$t('common_scheduled_period_1'), value:1},
                {name:this.$t('common_scheduled_period_7'), value:7},
                {name:this.$t('common_scheduled_period_30'), value:30},
                {name:this.$t('common_scheduled_period_365'), value:365}
            ],
            selectedFrequency:30,
        }
    },
    computed:{
        displayScheduledSummary(){

            let dateCurrentExport = '-'
            if(this.currentExport.pushedAt){
                dateCurrentExport = new Date(this.currentExport.pushedAt)
                dateCurrentExport = new Date(dateCurrentExport.getFullYear(), dateCurrentExport.getMonth(), dateCurrentExport.getDate(),12);
                dateCurrentExport = dateCurrentExport.toLocaleDateString('fr-FR', { timeZone: 'UTC' })
            }

            return !this.currentExport ? '' :this.$t('component_exportSetting_scheduleConfigPeriodSummary')
                .replace('[*ACCOUNT*]',this.currentExport.dataSource.identifier)
                .replace('[*PROJECT*]',this.projectInfo.data.name)
                .replace('[*PERIOD*]',this.$t(`component_exportSetting_scheduleConfigPeriodSelect_period_${this.selectedFrequency}`))
                .replace('[*PERIOD-COMPARE*]',this.$t(`component_exportSetting_scheduleConfigPeriodSelect_period_${this.selectedFrequency}`))
                .replace('[*DATE-LAST-EXPORT*]',dateCurrentExport)
                .replace('[*DATE-NEXT-EXPORT*]',this.getNextReportingDate(this.selectedFrequency))
        }
    },
    methods:{
        saveConfig(displayMode = 'account'){

            exportApiCalls.updateExport(this.currentExport.id, {dateRangeType:this.selectedFrequency, enabled:this.useExport})
            .then(()=>{
                exportApiCalls.updateExportDataSource(this.currentExport.id, this.selectedDataSource.id).then(()=>{
                    if(displayMode === 'account'){
                        this.displayConfirmSaveAccount = true
                    }
                    else{
                        this.displayConfirmSaveFrequency = true
                    }

                    setTimeout(() => {
                        if(displayMode === 'account'){
                            this.displayConfirmSaveAccount = false
                        }
                        else{
                            this.displayConfirmSaveFrequency = false
                        }
                    }, 5000);
                })

            })
        },

        initComponentData(){
            this.getComponentData( {
                componentName:'dataSources',
                promise: dataSourceApiCalls.getDataSources(this.$route.params.subscriptionId, 'google_sheet')
            }).then(()=>{
                this.getComponentData( {
                    componentName:'projectInfo',
                    promise: projectApiCalls.getProjectInfo(this.$route.params.projectId),
                    dataFormatter: this.projectData
                }).then(()=>{
                    this.currentExport = this.projectInfo.data.export
                    if(!this.currentExport.dataSource){
                        this.currentExport.dataSource = this.dataSources.data[0]
                    }
                    this.selectedFrequency = this.currentExport.dateRangeType
                    this.useExport = this.currentExport.enabled
                    this.selectedDataSource = this.currentExport.dataSource
                })
            })
        },
    },
    components:{
        DataSourceConfiguration
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">

</style>
