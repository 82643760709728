
import axios from '@/api/axios.js'

export default {

    /**
     * @param exportId int
     * @param param
     * @returns Promise
     */
    updateExport (exportId, param) {
        return axios.patch(`/configuration/exports/${exportId}`, param)
    },

    /**
     * @param exportId int
     * @param dataSourceId int
     * @returns Promise
     */
    updateExportDataSource (exportId, dataSourceId) {
        return axios.post(`/configuration/exports/${exportId}/data-sources/${dataSourceId}`)
    },


};
