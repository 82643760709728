<template>
    <div>
        <v-row>
            <v-col :cols="12" class="v-application pl-4 pr-4 mt-4" v-if="displayAlertExport">
                <v-alert
                    text
                    dense
                    outlined
                    color="primary"
                    icon="mdi-information-outline"
                    border="left"
                    class="bordered-alert"
                >
                    <div v-html="$t(`view_exportConfiguration_headerText`)"></div>
                </v-alert>
            </v-col>
            <v-col :cols="6">
                <export-setting/>
            </v-col>
        </v-row>
    </div>

</template>


<script>

import ExportSetting from "@/components/yooda-components/reporting/ExportSetting";

export default {
    data() {
        return {
            displayAlertExport:false,
        }
    },

    components: {
        ExportSetting
    },
    beforeMount() {
        if(!localStorage.getItem('displayedExportHeaderAlert')){
            localStorage.setItem('displayedExportHeaderAlert','true')
            this.displayAlertExport = true
        }
    },
}

</script>

